import React, { useEffect, useState } from 'react'
import { Col, Form, Label, Row, Spinner, UncontrolledTooltip } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';
import Lightbox from 'react-image-lightbox';
import { getImageUrl, showToast } from 'helpers/utils';
import { connect, useDispatch } from 'react-redux';
import { getSettings } from 'store/actions';

const FeatureSection = ({ data }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState('')

  const { mutate, isLoading: submitLoading, isSuccess } = useMutation({
    mutationFn: async (values) => {
      const formData = new FormData();
      for (const [key, value] of Object.entries(values)) {
        if (value instanceof FileList) {
          formData.append(key, value[0])
        }else{
          formData.append(key, value)
        }
      }
      await api.updateSettings(formData)
    },
    onSuccess: () => {
      showToast('Successfully saved settings.');
    },
  })

  useEffect(() => {
    reset(data);
  }, [data]);

  useEffect(() => {
    dispatch(getSettings());
  }, [isSuccess]);

  return !data ? (
    <Spinner className="mx-auto" />
  ) : (
    <Form onSubmit={handleSubmit(mutate)}>
      <h3>
        Feature Section
        <i
          className="fa fa-question-circle ms-2"
          id="section-1"
          style={{ fontSize: 16 }}
        ></i>
      </h3>
      <UncontrolledTooltip placement="right" target="section-1">
        <img src="/sections/0.png" alt="" width="100%" />
      </UncontrolledTooltip>

      <Row>
        <Col md={6}>

          <div className="mb-3">
            <Label>Solution 1</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Solution 1"
              {...register("feature_section_title_1", { required: true })}
            />
            {errors.feature_section_title_1 && (
              <small className="text-danger">This field is required</small>
            )}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label>Description 1</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Description"
              {...register("feature_section_description_1", { required: true })}
            />
            {errors.feature_section_description_1 && (
              <small className="text-danger">This field is required</small>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label>Solution 2</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Solution 2"
              {...register("feature_section_title_2", { required: true })}
            />
            {errors.feature_section_title_2 && (
              <small className="text-danger">This field is required</small>
            )}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label>Description 2</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Description"
              {...register("feature_section_description_2", { required: true })}
            />
            {errors.feature_section_description_2 && (
              <small className="text-danger">This field is required</small>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label>Solution 3</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Solution 3"
              {...register("feature_section_title_3", { required: true })}
            />
            {errors.feature_section_title_3 && (
              <small className="text-danger">This field is required</small>
            )}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label>Description 3</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Description"
              {...register("feature_section_description_3", { required: true })}
            />
            {errors.feature_section_description_3 && (
              <small className="text-danger">This field is required</small>
            )}
          </div>
        </Col>
      </Row>

      <div>
        <button
          type="submit"
          className="btn btn-primary w-md"
          disabled={submitLoading}
        >
          Submit
        </button>
      </div>
    </Form>
  )
}

export default connect((state) => ({
  data: state.settings?.settings
}))(React.memo(FeatureSection));