import { Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import TabFeature from './Tab/TabFeature';
import TabCard from './Tab/TabCard';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import { getSettings } from 'store/actions';
import { connect, useDispatch } from 'react-redux';

const SecondSection = ({ data }) => {
  const methods = useForm();
  const dispatch = useDispatch();
  const [customActiveTab, setcustomActiveTab] = useState(1);

  const { mutate, isLoading: submitLoading, isSuccess } = useMutation({
    mutationFn: async (values) => {
      const formData = new FormData();
      console.log(values)
      for (const [key, value] of Object.entries(values)) {
        if (value instanceof FileList) {
          formData.append(key, value[0])
        } else {
          formData.append(key, value)
        }
      }
      await api.updateSettings(formData)
    },
    onSuccess: () => {
      showToast('Successfully saved settings.');
    },
  })

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  useEffect(() => {
    methods.reset(data)
  }, [data])

  useEffect(() => {
    dispatch(getSettings());
  }, [isSuccess]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(mutate)}>
        <h3>
          Second Section
          <i
            className="fa fa-question-circle ms-2"
            id="section-2"
            style={{ fontSize: 16 }}
          ></i>
        </h3>
        <UncontrolledTooltip placement="right" target="section-2">
          <img src="/sections/2.png" alt="" width="100%" />
        </UncontrolledTooltip>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <Label>Counter Title 1</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Counter Title 1"
                {...methods.register("second_section_counter_title_1", {
                  required: true,
                })}
              />
              {methods.formState.errors.second_section_counter_title_1 && (
                <small className="text-danger">This field is required</small>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label>Counter 1</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Counter"
                {...methods.register("second_section_counter_1", {
                  required: true,
                })}
              />
              {methods.formState.errors.second_section_counter_1 && (
                <small className="text-danger">This field is required</small>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <Label>Counter Title 2</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Counter Title 2"
                {...methods.register("second_section_counter_title_2", {
                  required: true,
                })}
              />
              {methods.formState.errors.second_section_counter_title_2 && (
                <small className="text-danger">This field is required</small>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label>Counter 2</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Counter"
                {...methods.register("second_section_counter_2", {
                  required: true,
                })}
              />
              {methods.formState.errors.second_section_counter_2 && (
                <small className="text-danger">This field is required</small>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <Label>Counter Title 3</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Counter Title 3"
                {...methods.register("second_section_counter_title_3", {
                  required: true,
                })}
              />
              {methods.formState.errors.second_section_counter_title_3 && (
                <small className="text-danger">This field is required</small>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label>Counter 3</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Counter"
                {...methods.register("second_section_counter_3", {
                  required: true,
                })}
              />
              {methods.formState.errors.second_section_counter_3 && (
                <small className="text-danger">This field is required</small>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <Label>Counter Title 4</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Counter Title 4"
                {...methods.register("second_section_counter_title_4", {
                  required: true,
                })}
              />
              {methods.formState.errors.second_section_counter_title_4 && (
                <small className="text-danger">This field is required</small>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label>Counter 4</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Counter"
                {...methods.register("second_section_counter_4", {
                  required: true,
                })}
              />
              {methods.formState.errors.second_section_counter_4 && (
                <small className="text-danger">This field is required</small>
              )}
            </div>
          </Col>
        </Row>

        <div>
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
          >
            Submit
          </button>
        </div>
      </form>
    </FormProvider>
  )
}

export default connect((state) => ({
  data: state.settings?.settings
}))(React.memo(SecondSection));